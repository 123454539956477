import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/organisms/NavBar';
import AppRoutes from './App.Routes';
import { ToastProvider } from './utils/ToastContext';
import ToastContainer from './components/organisms/ToastContainer';
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { checkAuthState } from './redux/slices/authSlice';
import { RootState } from "./redux/rootReducer"; // Adjust as needed

const App: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  useEffect(() => {
    console.log("Checking user authentication state...");
    dispatch(checkAuthState());
  }, [dispatch]);

  return (
    <ToastProvider>
      <ToastContainer />
      <Router>
        <NavBar />
        <AppRoutes />
      </Router>
    </ToastProvider>
  );
};

export default App;
