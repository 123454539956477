import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/custom.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import logger from './utils/logger';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './redux/store';
logger.configure({
  auth: true,
  api: true,
  ui: false,
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Auth0Provider
  domain="dev-tfy0dk0vnxhe6hwo.us.auth0.com"
  clientId="4aPISrAYgaMWYerpyPb4JUC8tN9aL2ow"
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
><Provider store={store}><App /></Provider></Auth0Provider>);

reportWebVitals();
