import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleAuthCallback } from '../../redux/slices/authSlice';
import { useNavigate } from "react-router-dom";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../redux/rootReducer"; // Adjust as needed

const AuthCallback = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const navigate = useNavigate();

  useEffect(() => {
    const processCallback = async () => {
      await dispatch(handleAuthCallback()); // Properly dispatches the async thunk
      navigate("/"); // Redirect after authentication
    };

    processCallback();
  }, [dispatch, navigate]);

  return <div>Processing authentication...</div>;
};

export default AuthCallback;