import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import type { RootState } from "../rootReducer";
import { Auth0Client } from '@auth0/auth0-spa-js';

let auth0: Auth0Client | null = null;

const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

// Function to initialize Auth0
const initAuth0 = async () => {
  console.log(domain, clientId);
  if (!auth0) {
    auth0 = new Auth0Client({
      domain,
      clientId,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/authcallback`,
        audience
      },
    });
  }
  return auth0;
};

export interface User {
  uid: string;
  email: string | null;
  displayName: string | null;
  token: string | null; 
  role: string | null;
  currentUser: any | null;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    logoutSuccess(state) {
      state.user = null;
      state.isAuthenticated = false;
    },
    logoutFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setDisplayName: (state, action: PayloadAction<string | null>) => {
      if (state.user) {
        state.user.displayName = action.payload;
      }
    },
  },
});

export const { setUser, clearUser, setDisplayName } = authSlice.actions;

export const loginUser = (): AppThunk => async (dispatch) => {
  try {
    const auth0Client = await initAuth0();
    await auth0Client.loginWithRedirect();
  } catch (error) {
    console.error('Error logging in:', error);
  }
};

const fetchAuthState = async (dispatch: any) => {
  try {
    const auth0Client = await initAuth0();

    const token = await auth0Client.getTokenSilently();
    if (token) {
      console.log(token)
      const user = await auth0Client.getUser();
      dispatch(setUser({
        uid: user?.sub!,
        email: user?.email || null,
        displayName: user?.name || null,
        token,
        role: null, // Fetch roles separately if needed
        currentUser: user,
      }));
    } else {
      dispatch(clearUser());
    }
  } catch (error) {
    console.error("Error fetching auth state:", error);
    dispatch(clearUser()); // Ensure we clear state on errors
  }
};

export const logout = (): AppThunk => async (dispatch) => {
  try {
    const auth0Client = await initAuth0();
    await auth0Client.logout();
    dispatch(clearUser());
  } catch (error) {
    console.error('Error logging out:', error);
  }
};

export const checkAuthState = (): AppThunk => async (dispatch) => {
  await fetchAuthState(dispatch);
};

export const handleAuthCallback = (): AppThunk => async (dispatch) => {
  await fetchAuthState(dispatch);
};

export const selectJwtToken = (state: RootState) => state.auth.user?.token;

export const updateDisplayName = (displayName: string | null): AppThunk => (dispatch) => {
  dispatch(setDisplayName(displayName));
};

export default authSlice.reducer;