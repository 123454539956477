import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavLinks from '../molecules/NavLinks';
import BlackLogo from "../images/BlackLogo.svg"
import { useLocation } from 'react-router-dom';
import DigitalClock from '../molecules/DigitalClock';


const NavBar: React.FC = () => {
    const location = useLocation();
    const isVitalsBoard = location.pathname === '/vitalsboard';
    const expand = (isVitalsBoard) ? false : "sm"
    return (
        <Navbar bg="light" expand={expand} sticky='top'>
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src={BlackLogo}
                        width="100"
                        className="d-inline-block align-top"
                        alt="Brisby logo"
                    /></Navbar.Brand>
                { isVitalsBoard && <DigitalClock backgroundColor='transparent' textColor='#000000' isMilitaryTime /> }
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <NavLinks />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
