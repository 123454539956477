import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { loginUser } from '../../redux/slices/authSlice';
import FormField from '../molecules/FormField';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import Card from 'react-bootstrap/Card';
import Button from '../atoms/Button';
import { Container } from 'react-bootstrap';
import { ThunkDispatch } from "@reduxjs/toolkit";


const LoginPage: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  return (
    <Container>
      <br />
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>Login</Card.Title>
            <Button className="btn btn-primary" onClick={() => dispatch(loginUser())} >Log In</Button>
        </Card.Body>
      </Card></Container>
  );
};

export default LoginPage;
